/* WeatherModal.css */

.weather-info {
  display: grid;
  grid-template-areas: 
    "temp-icon weather-details"
    "temp-icon summary";
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 14px;
}

.center-date {
  text-align: center;
  width: 100%;
  color: #7e7d7d;
  padding-bottom: 10px;
  font-size: 14px;
}

.temp-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.temp-high-icon {
  display: flex;
  align-items: center;
}

.high-temp {
  font-size: 36px;
  font-weight: bold;
  margin-right: 8px;
}

.weather-icon {
  width: 65px;
  height: 65px;
}

.low-temp {
  margin-top: 8px;
  font-size: 18px;
}

.weather-details {
  display: grid;                /* Use grid layout */
  grid-template-columns: repeat(2, 1fr); /* Create 2 equal columns */
  gap: 5px;                   /* Space between grid items */
  grid-area: weather-details;
}

.weather-details p {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  margin-top: 10px;
  padding: 10px 0;
}

.weather-details .icon {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}

.weather-details .tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 30%;
  transform: translateX(-75%);
  opacity: 0;
  transition: opacity 0.3s;
}

.weather-details .tooltip::after {
  position: absolute;
}

.weather-details p:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.summary {
  grid-area: summary;
  margin-top: 10px;
  text-align: left;
}

.divider {
  width: 100%;
  margin-top: 10px;
}