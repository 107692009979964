/* WelcomeModal.css */

.welcome-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(230, 230, 250, 0.8) 100%);
  border-radius: 15px;
  padding: 30px;
  max-width: 600px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(220, 189, 189, 0.1);
  color: #333;
}

.welcome-modal h1 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #ff6f61; /* Sunset color */
  text-align: center;
}

.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.close-welcome-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background: linear-gradient(135deg, #ff6f61 0%, #ff9a8b 100%);
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
}

.close-welcome-button:hover {
  background: linear-gradient(135deg, #ff9a8b 0%, #ff6f61 100%);
  transform: translateY(-2px);
}

.close-welcome-button:active {
  transform: translateY(1px);
}

.close-welcome-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 105, 97, 0.5);
}

.author-linkedin {
  display: flex;
  align-items: center;
}

.author-linkedin p {
  margin: 0;
}

.linkedin-logo {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-top: 4px;
}
