/* LoadingSpinner.css */

.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(174, 172, 172, 0.25);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .sun {
    position: absolute;
    width: 60px;
    height: 60px;
    background: radial-gradient(circle, #f39c12, #f1c40f);
    border-radius: 50%;
    animation: rotate-sun 1.5s linear infinite;
  }
  
  .cloud {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 60px;
    height: 30px;
    background: #eef2f2;
    border-radius: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: move-cloud 2s linear infinite;
  }
  
  @keyframes rotate-sun {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes move-cloud {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(20px); }
  }