/* global.css */

/* Import modern font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

/* Global styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
}

/* Prevent body scroll when modal is open */
body.modal-open {
  overflow: hidden;
}

#map {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}