/* Modal.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  width: 75%;
  max-width: 500px;
  max-height: 75vh;
  background: rgba(255, 255, 255, 0.827);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 50%;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
}

.close-button:focus {
  outline: none;
}

.modal-header, .modal-title {
  padding: 1px 5px 5px 5px;
  text-align: center;
}

.title-with-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button {
  margin-left: 10px;
  padding: 5px 15px;
  margin-bottom: 4px;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

.toggle-button:hover {
  background: linear-gradient(135deg, #00c6fb 0%, #005bea 100%);
  transform: translateY(-2px);
}

.toggle-button:active {
  transform: translateY(1px);
}

.toggle-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 242, 254, 0.5);
}

.geocoded-location {
  color: #888; /* Lighter grey color */
  margin-bottom: 20px;
  font-size: 18px;
}